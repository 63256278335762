<template>
  <div>
    <h2
      v-if="title"
      v-html="title"
      class="text-center mt-5 highlights-title"
    ></h2>
    <div v-if="config.overwrite != 2 && highlights.length > 0">
      <div
        v-for="(itemGroup, highlightTypeIndex) in highlights"
        :key="'highlight_box_type_' + highlightTypeIndex"
        class="highlight-item"
      >
        <template v-if="itemGroup.type == 'imagebox'">
          <image-box
            v-if="itemGroup?.items?.some(item => item.type === 'card')"
            :highlights="getHighlightsForImagebox(itemGroup, 'card')"
            :designData="designData"
          ></image-box>
          <gallery-highlight
            v-if="itemGroup?.items?.some(item => item.type === 'gallery')"
            :content="getHighlightsForImagebox(itemGroup, 'gallery')"
            :arrowsColor="colorForArrows"
          ></gallery-highlight>
        </template>
        <template
          v-else-if="itemGroup.type == 'box' || itemGroup.type == 'highlight'"
        >
          <highlight-box
            v-for="(highlight, highlightIndex) in itemGroup.items"
            :key="'highlight_index_' + highlightIndex"
            :designData="designData"
            :highlightContent="highlightContentLoaded"
            :highlight="highlight"
          ></highlight-box>
        </template>
        <template v-else-if="itemGroup.type == 'accordion'">
          <accordion
            :design-data="designData"
            :highlights="itemGroup.items"
          ></accordion>
        </template>
        <template v-else-if="itemGroup.type == 'custom-html'">
          <custom-element
            v-for="(highlight, highlightIndex) in itemGroup.items"
            :datesForSkyAlps="datesForSkyAlps"
            :key="'custom_html_index_' + highlightIndex"
            :content="highlight"
            :parseAssets="true"
          ></custom-element>
        </template>
      </div>
    </div>
    <div v-else>
      <custom-element
        v-if="content.find((item) => item.type == 'block')"
        :content="content.find((item) => item.type == 'block')"
        :parse-assets="true"
      ></custom-element>
    </div>
  </div>
</template>

<script>
import CustomElement from "@/components/CustomElement";
import { lpMixins } from "@/mixins/lpMixins";
import HighlightBox from "@/components/blocks/highlights/HighlightBox";
import ImageBox from "@/components/blocks/highlights/ImageBox";
import Accordion from "@/components/blocks/highlights/Accordion";
import GalleryHighlight from "@/components/blocks/highlights/GalleryHighlight.vue";

export default {
  components: {
    // MiniSlider,
    CustomElement,
    ImageBox,
    Accordion,
    HighlightBox,
    GalleryHighlight
  },
  mixins: [lpMixins],
  name: "Highlights",
  props: [
    /**
     * datesForSkyAlps
     * { from_date: Date, to_date: Date }
     */
    "datesForSkyAlps",
    "title",
    "page",
    "designData",
    "highlightContentLoaded",
    "config",
    "content"
  ],
  data() {
    return {
      windowWidth: window.innerWidth,
      cardContentLoaded: [],
      modalCardObject: null,
      ready: false,
      highlights: []
    };
  },
  mounted() {
   this.refactorContent();

    let structure = [];
    let structureIndex = 0;
    let lastType = null;
    let runVar = 0;

    this.content.forEach((item) => {
      if (item.config.style != lastType) {
        if (runVar != 0) structureIndex++;
        lastType = item.config.style;
        structure[structureIndex] = {
          type: item.config.style,
          items: [item]
        };
      } else {
        structure[structureIndex].items.push(item);
      }
      runVar++;
    });

    this.highlights = structure;

    if ("block" in this.content) {
      this.parseForScript(true, this.content.block);
    }

    if ("card" in this.content && this.content.card.length > 0) {
      new Promise((resolve) => {
        if (
          this.content.card &&
          this.content.card.filter(
            (c) => c.content_data.data.components[0].config.expanded
          ).length > 0
        ) {
          this.content.card.filter(
            (c) => c.content_data.data.components[0].config.expanded
          );
        }
        resolve();
      }).then(() => {
        this.ready = true;
      });
    } else {
      this.ready = true;
    }
  },
  methods: {
    returnImgWidth(width = false, ratio = 2.36) {
      let theWidth = width || this.windowWidth;
      return theWidth === 0
        ? "1024x433"
        : theWidth + "x" + parseInt(theWidth / ratio);
    },
    refactorContent() {
      this.content.map((item) => {
        if (item.type === "block.html") {
          item.config.style = "custom-html";
        } else if (item.config.style) {
          // No need to change item.config.style if it already has a value
        } else if (item.type === "block" || item.type === "block.lp") {
          item.config.style = "highlight";
        } else if (item.config.expanded) {
          item.config.style = "box";
        } else {
          item.config.style = "imagebox";
        }
      })?.sort(function (a, b) {
        if (a.priority > b.priority) return +1;
        if (a.priority < b.priority) return -1;
        return 0;
      });
    },
    getHighlightsForImagebox(group, highlightType){
      const {items, type} = group;
      return {
        items: items.filter(item => item.type === highlightType),
        type
      }
    }
  },
  computed:{
    colorForArrows() {
      return this.designData.style.highlights?.arrows_color || this.designData.style.general["font-color"];
    }
  }
};
</script>

<style scoped lang="sass">
@import "../styles/components/highlights/Highlights.sass"
</style>